module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KK9MNS6Z","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RP8VD30894"],"pluginConfig":{"head":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.rebel.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.rebel.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RebelCMS","short_name":"rebel-cms","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/updated-rebel-logo-favicon.png","icons":[{"src":"/cms-assets/icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/cms-assets/icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/cms-assets/icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/cms-assets/icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/cms-assets/icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/cms-assets/icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/cms-assets/icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/cms-assets/icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"898f735625c35a196a4f6d37daf96851"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/codebuild/output/src2260673223/src/src/templates/markdown-page.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/codebuild/output/src2260673223/src"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
